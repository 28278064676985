export default `
  fragment ListBookFragment on list_books {
    id
    listId:list_id
    bookId:book_id
    editionId:edition_id
    position
    reason
  }
`;
