import { gql } from "graphql-tag";

import FollowedListFragment from "queries/followed_lists/fragments/FollowedListFragment";
import FollowedPromptFragment from "queries/followed_prompts/fragments/FollowedPromptFragment";
import FollowedUserFragment from "queries/followed_users/fragments/FollowedUserFragment";
import UserBookStatusFragment from "queries/user_books/fragments/UserBookStatusFragment";
import OwnerFragment from "queries/users/fragments/OwnerFragment";
import UserBookReadFragment from "./user_book_reads/fragments/UserBookReadFragment";
import EditionFragment from "./editions/fragments/EditionFragment";
import PublisherFragment from "./publishers/fragments/PublisherFragment";
import BookFragment from "./books/fragments/BookFragment";
import BookSeriesFragment from "./books/fragments/BookSeriesFragment";
import SeriesFragment from "./series/fragments/SeriesFragment";
import AuthorFragment from "./authors/fragments/AuthorFragment";
import UserBylineFragment from "./users/fragments/UserBylineFragment";
import ListFragment from "./lists/fragments/ListFragment";
import ListBookFragment from "./listBooks/fragments/ListBookFragment";
import LikeFragment from "./likes/fragments/LikeFragment";
import BookBylineFragment from "./books/fragments/BookBylineFragment";
import PromptFragment from "./prompts/fragments/PromptFragment";
import AuthorBylineFragment from "./authors/fragments/AuthorBylineFragment";
import PromptAnswerFragment from "./prompts/fragments/PromptAnswerFragment";
import UserInfoFragment from "./users/fragments/UserInfoFragment";
import CollectionImportResultFragment from "./collection_imports/fragments/CollectionImportResultFragment";
import MatchPercentageFragment from "./recommendations/fragments/MatchPercentageFragment";
import ReadingJournalSummaryFragment from "./journals/fragments/ReadingJournalSummaryFragment";
import ReadingJournalFragment from "./journals/fragments/ReadingJournalFragment";
import UserBookFragment from "./user_books/fragments/UserBookFragment";
import ActivityFragment from "./feed/fragments/ActivityFragment";
import GoalFragment from "./goals/fragments/GoalFragment";
import NotificationFragment from "./notifications/fragments/NotificationFragment";
import NotificationDeliveryFragment from "./notifications/fragments/NotificationDeliveryFragment";
import UserBlockFragment from "./user_blocks/fragments/UserBlockFragment";
import TaggingFragment from "./tags/fragments/TaggingFragment";
import TagFragment from "./tags/fragments/TagFragment";
import BookCharacterFragment from "./book_characters/fragments/BookCharacterFragment";
import CharacterFragment from "./characters/fragments/CharacterFragment";
import ListBylineFragment from "./lists/fragments/ListBylineFragment";
import ImageFragment from "./images/fragments/ImageFragment";
import BookMappingFragment from "./book_mappings/fragments/BookMappingFragment";
import EditEditionFragment from "./editions/fragments/EditEditionFragment";
import BookEditFragment from "./books/fragments/BookEditFragment";
import CollectionImportFragment from "./collection_imports/fragments/CollectionImportFragment";
import CountryFragment from "./countries/fragments/CountryFragment";
import LanguageFragment from "./languages/fragments/LanguageFragment";
import NotificationTypeFragment from "./notifications/fragments/NotificationTypeFragment";
import SeriesEditFragment from "./series/fragments/SeriesEditFragment";
import UserBookFullFragment from "./user_books/fragments/UserBookFullFragment";
import PromptBookSummaryFragment from "./prompts/fragments/PromptBookSummaryFragment";

export default gql`
  ${ActivityFragment}
  ${AuthorBylineFragment}
  ${AuthorFragment}
  ${BookBylineFragment}
  ${BookEditFragment}
  ${BookCharacterFragment}
  ${BookFragment}
  ${BookMappingFragment}
  ${BookSeriesFragment}
  ${CharacterFragment}
  ${CollectionImportFragment}
  ${CollectionImportResultFragment}
  ${CountryFragment}
  ${EditEditionFragment}
  ${EditionFragment}
  ${FollowedListFragment}
  ${FollowedPromptFragment}
  ${FollowedUserFragment}
  ${GoalFragment}
  ${ImageFragment}
  ${LanguageFragment}
  ${LikeFragment}
  ${ListFragment}
  ${ListBookFragment}
  ${ListBylineFragment}
  ${MatchPercentageFragment}
  ${NotificationDeliveryFragment}
  ${NotificationFragment}
  ${NotificationTypeFragment}
  ${OwnerFragment}
  ${PromptAnswerFragment}
  ${PromptFragment}
  ${PublisherFragment}
  ${ReadingJournalFragment}
  ${ReadingJournalSummaryFragment}
  ${SeriesEditFragment}
  ${SeriesFragment}
  ${TagFragment}
  ${TaggingFragment}
  ${UserBlockFragment}
  ${UserBookFragment}
  ${UserBookFullFragment}
  ${UserBookReadFragment}
  ${UserBookStatusFragment}
  ${UserBylineFragment}
  ${UserInfoFragment}
  ${PromptBookSummaryFragment}
`;
