export default `
fragment SeriesEditFragment on series {
  id
  name
  slug
  locked
  description
  isCompleted:is_completed
  authorId:author_id
  state
  booksCount:books_count
  primaryBooksCount:primary_books_count
  author {
    ...AuthorFragment
  }
}
`;
