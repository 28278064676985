export default `
fragment BookBylineFragment on books {
  id
  slug
  title
  rating
  releaseDate:release_date
  releaseYear:release_year
  cachedImage:cached_image
  usersCount:users_count
  usersReadCount:users_read_count
  compilation
  contributions {
    contribution
    author {
      name
      slug
      cachedImage:cached_image
      booksCount:books_count
    }
  }
}
`;
