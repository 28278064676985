export default `
fragment BookMappingFragment on book_mappings {
  id
  bookId: book_id
  editionId:edition_id
  platformId:platform_id
  platform {
    name
  }
  state
  externalId:external_id
  verifiedAt:verified_at
  loadedAt:loaded_at
  dtoExternal:dto_external
}
`;
