export default `
fragment UserBookReadFragment on user_book_reads {
  id
  userBookId:user_book_id
  startedAt:started_at
  finishedAt:finished_at
  editionId:edition_id
  progress
  progressPages:progress_pages
  progressSeconds:progress_seconds
  edition {
    ...EditionFragment
  }
}
`;
