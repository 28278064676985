"use client";

import { NextSSRApolloClient } from "@apollo/experimental-nextjs-app-support/ssr";
import { NormalizedCacheObject } from "@apollo/client";
import { makeClient } from "./ssrClient";

// Saved apolloClient for the duration of this request
// This can be used for anything not using useQuery, useLazyQuery, etc
let apolloClient: NextSSRApolloClient<NormalizedCacheObject> | null = null;
function getClient() {
  if (apolloClient) {
    return apolloClient;
  }
  apolloClient = makeClient()();
  return apolloClient;
}

export { getClient };
