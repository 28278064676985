export default `
  fragment ActivityFragment on activities {
    id
    data
    event
    likesCount:likes_count
    createdAt:created_at
    userId:user_id
    objectType:object_type
  }
`;
