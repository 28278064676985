"use client";

/* eslint-disable no-console */
import { HttpLink } from "@apollo/client/link/http";
import {
  ApolloNextAppProvider,
  NextSSRApolloClient,
  SSRMultipartLink,
} from "@apollo/experimental-nextjs-app-support/ssr";
import { from } from "@apollo/client";
import { HardcoverSession, fetchSession } from "lib/authorization/tokenLoader";
import { setContext } from "@apollo/client/link/context";
// import { setVerbosity } from "ts-invariant";
// import { loadErrorMessages, loadDevMessages } from "@apollo/client/dev";
import { onError } from "@apollo/client/link/error";
import cache from "./cache";

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  console.log(
    `[GraphQL error]: Operation: ${
      operation.operationName
    }, variables: ${JSON.stringify(operation.variables)}`
  );
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});

if (process.env.NODE_ENV === "development") {
  // setVerbosity("debug");
  // loadErrorMessages();
  // loadDevMessages();
}

export function makeClient(session?: HardcoverSession) {
  // Manages server client state
  const ssrMode = typeof window === "undefined";

  return function getClient() {
    const httpLink = new HttpLink({
      uri: ssrMode
        ? process.env.SSR_GRAPHQL_URL
        : process.env.NEXT_PUBLIC_GRAPHQL_URL,
      fetchOptions: {
        cache: process.env.BUILDING ? "force-cache" : "no-store",
      },
    });

    // SSR Client: Use the session from the client
    const authLink = setContext((_, { headers }) => {
      return fetchSession().then((newSession) => {
        return {
          headers: {
            ...headers,
            ...{ authorization: `Bearer ${newSession.token}` },
          },
        };
      });
    });

    // SSR Server: Use the session from the server
    const ssrLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          ...{ authorization: `Bearer ${session.token}` },
        },
      };
    });

    const activeLink = ssrMode
      ? ssrLink.concat(httpLink)
      : authLink.concat(httpLink);

    const links = [
      // in a SSR environment, if you use multipart features like
      // @defer, you need to decide how to handle these.
      // This strips all interfaces with a `@defer` directive from your queries.
      new SSRMultipartLink({
        stripDefer: ssrMode,
      }),
      activeLink,
    ];

    if (process.env.NODE_ENV === "development") {
      links.unshift(errorLink);
    }
    const link = from(links);

    return new NextSSRApolloClient({
      ssrMode,
      connectToDevTools: !ssrMode,
      link,
      cache,
    });
  };
}

interface ApolloWrapperProps extends React.PropsWithChildren {
  session: HardcoverSession;
}
export function ApolloWrapper({ children, session }: ApolloWrapperProps) {
  return (
    <ApolloNextAppProvider makeClient={makeClient(session)}>
      {children}
    </ApolloNextAppProvider>
  );
}
