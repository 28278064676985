export default `
  fragment NotificationDeliveryFragment on notification_deliveries {
    id
    userId:user_id
    notificationId:notification_id
    read
    readAt:read_at
    sentAt:sent_at
    channelId:channel_id
  }
`;
