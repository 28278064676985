export default `
fragment AuthorFragment on authors {
  id
  slug
  name
  bio
  cachedImage:cached_image
  bornDate:born_date
  bornYear:born_year
  deathDate:death_date
  deathYear:death_year
  links
  booksCount:books_count
  namePersonal:name_personal
  location
  isBipoc:is_bipoc
  isLgbtq:is_lgbtq
  aliasId:alias_id
  genderId:gender_id
  locked
  imageId:image_id
  createdByUserId:user_id
  image {
    ...ImageFragment
  }
  alias {
    id
    name
    slug
    cachedImage:cached_image
  }
}
`;
