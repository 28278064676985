export default `
fragment MatchPercentageFragment on recommendations {
  subjectType:subject_type
  subjectId:subject_id
  itemType:item_type
  itemId:item_id
  context
  score
}
`;
