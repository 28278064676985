export default `
fragment UserBookFragment on user_books {
  id
  bookId: book_id
  editionId: edition_id
  userId: user_id
  statusId: status_id
  rating
  privacySettingId: privacy_setting_id
  hasReview: has_review

  edition {
    ...EditionFragment
  }

  datesRead:user_book_reads {
    ...UserBookReadFragment
  }
}
`;
