export default `
  fragment ListBylineFragment on lists {
    id
    name
    slug
    booksCount:books_count
    likesCount:likes_count
    privacySettingId:privacy_setting_id
  }
`;
