export default `
fragment TagFragment on tags {
  id
  tag
  count
  slug
  tagCategory:tag_category {
    category
    slug
  }
}
`;
