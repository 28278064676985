export default `
fragment EditionFragment on editions {
  id
  title
  asin
  isbn10:isbn_10
  isbn13:isbn_13
  releaseDate:release_date
  releaseYear:release_year
  pages
  audioSeconds:audio_seconds
  readingFormatId:reading_format_id
  usersCount:users_count
  cachedImage:cached_image
  editionFormat:edition_format
  editionInformation:edition_information
  language {
    id
    language
    code:code2
  }
  readingFormat:reading_format {
    format
  }
  country {
    name
  }
  publisher {
    ...PublisherFragment
  }
}
`;
