export default `
  fragment ReadingJournalFragment on reading_journals {
    id
    createdAtRaw:created_at
    updatedAtRaw:updated_at
    event
    entry
    userId:user_id
    bookId:book_id
    privacySettingId:privacy_setting_id
    metadata
    likesCount:likes_count
    objectType:object_type

    taggings {
      ...TaggingFragment
      tag {
        ...TagFragment
      }
    }

    edition {
      ...EditionFragment
    }

    likes(limit: 5, order_by: { created_at: desc }) {
      ...LikeFragment
      user {
        ...UserBylineFragment
      }
    }
  }
`;
