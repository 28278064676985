/* eslint-disable import/no-unresolved */
/* eslint-disable no-underscore-dangle */
import { NextSSRInMemoryCache } from "@apollo/experimental-nextjs-app-support/ssr";
import { createFragmentRegistry } from "@apollo/client/cache";
import fragments from "queries/fragments";

const cache = new NextSSRInMemoryCache({
  fragments: createFragmentRegistry(fragments),
  typePolicies: {
    followed_lists: {
      keyFields: ["userId", "listId"],
    },
    followed_prompts: {
      keyFields: ["userId", "promptId"],
    },
    followed_users: {
      keyFields: ["userId", "followedUserId"],
    },
    likes: {
      keyFields: ["likeableId", "likeableType", "userId"],
    },
    list_books: {
      keyFields: ["listId", "bookId"],
    },
    prompt_answers: {
      keyFields: ["promptId", "bookId", "userId"],
    },
    prompt_books_summary: {
      keyFields: ["promptId", "bookId"],
    },
    recommendations: {
      keyFields: ["subjectType", "subjectId", "itemType", "itemId", "context"],
    },
    user_books: {
      keyFields: ["userId", "bookId"],
    },
  },
});

export default cache;
