export default `
  fragment UserInfoFragment on users {
    id
    cachedImage:cached_image
    name
    username
    flair
    membership
    statusId:status_id
    bio
    birthdate
    location
    link
    followersCount:followers_count
    followedUsersCount:followed_users_count
    accountPrivacySettingId:account_privacy_setting_id
    statusId:status_id
    createdAt: created_at
    pronounPersonal:pronoun_personal
    pronounPossessive:pronoun_possessive
    booksCount:books_count
    librarianRoles:librarian_roles
    cachedGenres:cached_genres
    onboarded
    pro
  }
`;
