export default `
fragment BookFragment on books {
  id
  slug
  title
  cachedImage:cached_image
  headline
  description
  releaseDate:release_date
  releaseYear:release_year
  tags:cached_tags
  usersCount:users_count
  usersReadCount:users_read_count
  journalsCount:journals_count
  reviewsCount:reviews_count
  pages
  audioSeconds:audio_seconds
  ratingsCount:ratings_count
  rating
  compilation
  contributions {
    contribution
    author {
      id
      name
      slug
      cachedImage:cached_image
      booksCount:books_count
    }
  }

  bookSeries: book_series(
    where: {
      book: { book_status_id: { _eq: 1 } }
      series: { canonical_id: { _is_null: true } }
    }
  ) {
    ...BookSeriesFragment
    series {
      ...SeriesFragment
    }
  }
}
`;
