export default `
fragment EditEditionFragment on editions {
  bookId: book_id
  book {
    ...BookFragment
  }
  id
  title
  isbn10: isbn_10
  isbn13: isbn_13
  releaseDate: release_date
  pages
  readingFormatId: reading_format_id
  audioSeconds:audio_seconds
  cachedImage: cached_image
  imageId:image_id
  createdByUserId:created_by_user_id
  language {
    language
  }
  reading_format {
    format
  }
  image {
    ...ImageFragment
  }
  images {
    ...ImageFragment
  }
  dto
  dtoCombined: dto_combined
  dtoExternal: dto_external
  bookMappings:book_mappings {
    ...BookMappingFragment
  }
  publisher {
    ...PublisherFragment
  }
}
`;
