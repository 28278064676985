export default `
  fragment CollectionImportResultFragment on collection_import_results {
    id
    title
    author
    state
    bookFoundMethod:book_found_method
    report
  }
`;
