export default `
  fragment ImageFragment on images {
    id
    url
    color
    height
    width
    imageableId:imageable_id
    imageableType:imageable_type
  }
`;
