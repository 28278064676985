export default `
  fragment BookEditFragment on books {
    id
    bookStatusId:book_status_id
    slug
    title
    description
    locked
    dto
    dtoCombined:dto_combined
    dtoExternal:dto_external
    userAdded:user_added
    literaryTypeId: literary_type_id
    bookCategoryId: book_category_id
    createdByUserId: created_by_user_id
    defaultCoverEditionId:default_cover_edition_id
    defaultPhysicalEditionId:default_physical_edition_id
    defaultAudioEditionId:default_audio_edition_id
    defaultEbookEditionId:default_ebook_edition_id
    state

    contributions {
      author {
        name
      }
    }

    canonical {
      ...BookBylineFragment
    }

    defaultCoverEdition:default_cover_edition {
      ...EditionFragment
    }
    defaultPhysicalEdition:default_physical_edition {
      ...EditionFragment
    }
    defaultAudioEdition:default_audio_edition {
      ...EditionFragment
    }
    defaultEbookEdition:default_ebook_edition {
      ...EditionFragment
    }

    bookCharacters: book_characters(order_by: { position: asc }) {
      ...BookCharacterFragment
      character {
        ...CharacterFragment
      }
    }
  }
`;
