export default `
  fragment PromptAnswerFragment on prompt_answers {
    id
    createdAt:created_at
    promptId:prompt_id
    bookId:book_id
    userId:user_id
    description
  }
`;
