export default `
  fragment PromptFragment on prompts {
    id
    userId:user_id
    createdAt:created_at
    question
    privacySettingId:privacy_setting_id
    description
    featured
    usersCount:users_count
    booksCount:books_count
    answersCount:answers_count
    slug
  }
`;
