export default `
fragment UserBookFullFragment on user_books {
  id
  dateAdded:date_added
  owned
  rating
  lastReadDate:last_read_date
  firstReadDate:first_read_date
  statusId:status_id
  bookId:book_id
  userId:user_id
  editionId:edition_id
  updatedAt:updated_at
  privacySettingId:privacy_setting_id
  reviewedAt:reviewed_at
  url
  mediaUrl:media_url
  objectType:object_type
  likesCount:likes_count
  hasReview:has_review
  reviewSlate:review_slate,
  reviewLength:review_length
  reviewHasSpoilers:review_has_spoilers
  sponsoredReview:sponsored_review

  edition {
    ...EditionFragment
  }
}
`;
