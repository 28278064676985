export default `
  fragment UserBylineFragment on users {
    id
    username
    name
    cachedImage:cached_image
    flair
    booksCount: books_count
    followersCount: followers_count
    bio
  }
`;
