export default `
  fragment NotificationFragment on notifications {
    id
    title
    link
    linkText:link_text
    description
    priority
    notificationTypeId: notification_type_id
  }
`;
