export default `
  fragment OwnerFragment on users {
    id
    cachedImage:cached_image
    name
    username
    flair
    pro
    bio
    location
    link
    membership
    admin
    followersCount:followers_count
    followedUsersCount:followed_users_count
    accountPrivacySettingId:account_privacy_setting_id
    statusId:status_id
    createdAt:created_at
    pronounPersonal:pronoun_personal
    pronounPossessive:pronoun_possessive
    onboarded
    confirmedAt: confirmed_at
    confirmationSentAt: confirmation_sent_at
    email
    unconfirmedEmail: unconfirmed_email
    activityPrivacySettingsId:activity_privacy_settings_id
    birthdate
    booksCount:books_count
    librarianRoles:librarian_roles

    followedUsers: followed_users {
      ...FollowedUserFragment @nonreactive
    }
    followedLists: followed_lists {
      ...FollowedListFragment @nonreactive
    }
    followedPrompts: followed_prompts {
      ...FollowedPromptFragment @nonreactive
    }
    userBooks: user_books @nonreactive {
      ...UserBookStatusFragment @nonreactive
    }

    notifications: notification_deliveries_aggregate(
      where: { channel_id: { _eq: 1 }, read: { _eq: false } }
    ) {
      aggregate {
        count
      }
    }
  }
`;
