export default `
fragment SeriesFragment on series {
  id
  name
  slug
  booksCount:books_count
  primaryBooksCount:primary_books_count
  isCompleted:is_completed
  description
}
`;
