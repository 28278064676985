export default `
  fragment GoalFragment on goals {
    id
    archived
    userId:user_id
    completedAt:completed_at
    conditions
    endDate:end_date
    metric
    progress
    startDate:start_date
    description
    goal
    privacySettingId:privacy_setting_id
  }
`;
