export default `
  fragment CollectionImportFragment on collection_imports {
    id
    createdAt:created_at
    updatedAt:updated_at
    userId:user_id
    state
    reimportCount:reimport_count
    successCount:success_count
    processedCount:processed_count
    totalCount:total_count
    failureCount:failure_count
    currentBook:current_book
  }
`;
