export default `
  fragment NotificationTypeFragment on notification_types {
    id
    defaultChannelIds:default_channel_ids
    defaultPriority:default_priority
    description
    name
    uid
  }
`;
