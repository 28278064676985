export default `
fragment BookCharacterFragment on book_characters {
  id
  position
  spoiler
  onlyMentioned: only_mentioned
  bookId: book_id
  characterId: character_id
}
`;
