export default `
  fragment ListFragment on lists {
    id
    objectType:object_type
    name
    userId:user_id
    slug
    ranked
    description
    url
    privacySettingId:privacy_setting_id
    booksCount:books_count
    likesCount:likes_count
    featuredProfile:featured_profile
    defaultView:default_view
    createdAt: created_at
    updatedAt: updated_at
  }
`;
